import { Container, HStack } from '@chakra-ui/layout'
import React, { createElement, useCallback, useEffect, useMemo, useRef } from 'react'
import Slider from "react-slick";
import { AnimatePresence, motion } from 'framer-motion';
import { random, range } from 'lodash';

import BackgroundImage from '../../../components/BackgroundImage'
import Box from '../../../components/Box'
import { responsive } from '../../../contexts/responsive'

import track from './track.svg'
import Bang from './Bang'
import Heart from './Heart'
import Taiwan from './Taiwan'

const items = [
  Bang,
  Heart,
  Taiwan,
]
const speed = 50
const Track = ({ index = 0 }) => {
  const slickRef = useRef()
  const initialSlide = useMemo(() => random(items.length * 2), [])
  const timeoutRef = useRef()
  const iterRef = useRef()
  const indexRef = useRef()
  useEffect(() => {
    iterRef.current = 0
    const dir = index === 1 ? 'slickNext' : 'slickPrev'
    const tick = () => {
      if (slickRef.current) {
        slickRef.current[dir]()
        iterRef.current += 1
        const round = Math.ceil(iterRef.current / items.length)
        if (round <= 5 || indexRef.current !== (dir === 'slickNext' ? 5 : index)) {
          timeoutRef.current = setTimeout(tick, speed * round / 2)
        }
      }
    }
    setTimeout(tick, 800)
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
    }
  }, [])
  const handleChange = useCallback((newIndex) => {
    indexRef.current = newIndex
  }, [])
  return (
    <Box transform="translateY(-11.5%)">
      <Slider
        vertical
        slidesToScroll={1}
        slidesToShow={3}
        swipe
        arrows={false}
        touchMove={false}
        draggable={false}
        verticalSwiping
        accessibility={false}
        initialSlide={initialSlide}
        ref={slickRef}
        speed={speed}
        afterChange={handleChange}
      >
        {[...items, ...items].map((item, i) => createElement(item, {
          color: i === index ? 'black' : 'white',
          key: i,
        }))}
      </Slider>
    </Box>
  )
}

const Imagination = ({ in: isIn }) => {
  return (
    <Box.Absolute left="0" right="0" top="35vh" transform="translateY(-100%)">
      <Container>
        <AnimatePresence>
          {isIn && (
            <BackgroundImage
              ratio={380 / 212}
              src={track}
              width={responsive("46%", '35%')}
              mx="auto"
              as={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.75, delay: 1 } }}
              exit={{ opacity: 0 }}
            >
              <HStack height="100%" mx="7%" spacing="14%">
                {range(3).map(i => (
                  <Box flex="1" h="100%" key={i}>
                    <Track index={i} />
                  </Box>
                ))}
              </HStack>
            </BackgroundImage>
          )}
        </AnimatePresence>
      </Container>
    </Box.Absolute>
  )
}

export default Imagination
