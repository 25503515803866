import { useDisclosure } from '@chakra-ui/hooks'
import { useEffect, useState } from 'react'

const useDelayedOpen = (active, direction) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  useEffect(() => {
    if (active) {
      if (direction === 'down') {
        setTimeout(() => {
          onOpen()
        }, 1400)
      } else {
        onOpen()
      }
    } else {
      onClose()
    }
  }, [active])
  return { isOpen, onOpen, onClose }
}

export default useDelayedOpen
