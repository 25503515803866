import { Container, Grid, GridItem } from '@chakra-ui/layout'
import { motion } from 'framer-motion'
import React, { useCallback, useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import random from 'lodash/random'
import { useWindowSize } from 'react-use'

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

import Box from '../../../components/Box'
import { Media } from '../../../contexts/responsive'

const MotionGridItem = motion(GridItem)

const upper = [
  { rowSpan: 2, colSpan: 4, name: 'smart-future', direction: [-1, 0] },
  { colSpan: 3, name: '抱歉長大後才告訴你', direction: [0, -1] },
  { colSpan: 5, name: '發生什麼數', direction: [1, -1] },
  { colSpan: 5, name: '108林務局', direction: [0, 1] },
  { colSpan: 3, name: '山模山樣', direction: [1, 1] },
]

const lower = [
  { colSpan: 4, name: '看診', direction: [-1, 0] },
  { colSpan: 6, name: '數據百閱', direction: [0, -1] },
  { colSpan: 4, name: '當代購物', direction: [1, 0] },
  { colSpan: 6, name: '台灣動起來', direction: [-1, 1] },
  { colSpan: 2 },
  { colSpan: 6, name: '素顏台北', direction: [1, 1] },
]

// col: 10 row: 15
const desktopLeft = [
  { colSpan: 3, rowSpan: 2, name: '月老', direction: [-1, -1] },
  { colSpan: 6, rowSpan: 5, name: '發生什麼數', direction: [0, -1] },
  { colSpan: 3, rowSpan: 3, name: '回收大百科', direction: [-1, 0] },
  { colSpan: 6, rowSpan: 6, name: '108林務局', direction: [-1, 0] },
  { colSpan: 4, rowSpan: 6 },
  { colSpan: 5, rowSpan: 5, name: '當代購物', direction: [-1, 0] },
  { colSpan: 2, rowSpan: 3, name: '山模山樣', direction: [-1, 1] },
]

// col: 10 row: 15
const desktopRight = [
  { colSpan: 1, rowSpan: 3 },
  { colSpan: 4, rowSpan: 3, name: '艾雷島威士忌', direction: [0, -1] },
  { colSpan: 5, rowSpan: 6, name: '台灣動起來', direction: [1, -1] },
  { colSpan: 3, rowSpan: 3 },
  { colSpan: 2, rowSpan: 3, name: 'smart-future', direction: [1, 0] },
  { colSpan: 5, rowSpan: 6 },
  { colSpan: 5, rowSpan: 6, name: '數據百閱', direction: [1, 0] },
  { colSpan: 2, rowSpan: 4 },
  { colSpan: 6, rowSpan: 4, name: '素顏台北', direction: [1, 1] },
  { colSpan: 2, rowSpan: 4, name: '看診', direction: [1, 0] },
]


const getVariants = (delay = 0, [x, y]) => ({
  enter: {
    opacity: 1,
    x: 0,
    y: 0,
    transition: { delay, duration: 1 },
  },
  leave: {
    opacity: 0,
    x,
    y,
    transition: { duration: 1 },
  },
})

const WorkImage = ({ image }) => {
  // Use like this:
  const bgImage = convertToBgImage(image)

  return (
    <BackgroundImage
      Tag="section"
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
      style={{ width: '100%', height: '100%' }}
    >
    </BackgroundImage>
  )
}

const sizes = [
  ['sm', 'md'],
  ['md', 'lg'],
]

const Value = () => {
  const windowSize = useWindowSize()
  const { allFile: { nodes: images } } = useStaticQuery(graphql`
    query ValueFileQuery {
      allFile(filter: {sourceInstanceName: {eq: "value-images"}}) {
        nodes {
          name
          childImageSharp {
            sm: gatsbyImageData(
              width: 200
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP]
            )
            md: gatsbyImageData(
              width: 400
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP]
            )
            lg: gatsbyImageData(
              width: 600
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `)
  const imgObj = useMemo(() => images.reduce((all, img) => {
    all[img.name] = img.childImageSharp
    return all
  }, {}), [images])

  // console.log(imgObj)

  const makeWorks = useCallback((works, isDekstop) => works.map((work, i) => {
    const imgSize = sizes[isDekstop ? 1 : 0][work.rowSpan < 4 ? 0 : 1]
    // console.log(work.name, imgSize)
    return (
      <MotionGridItem
        key={i}
        variants={getVariants(random(0.5, 2), work.direction?.map(d => d * random(windowSize.width * 0.02, windowSize.width * 0.2)) ?? [0, 0])}
        rowSpan={work.rowSpan}
        colSpan={work.colSpan}
      >
        {work.name && (
          <WorkImage image={imgObj[work.name]?.[imgSize]} name={work.name} />
        )}
      </MotionGridItem>
    )
  }), [imgObj])

  // console.log(images)
  return (
    <>
      <Media lessThan="md">

        <Box.Absolute
          top="2em"
          left="-6%"
          right="-6%"
        >
          <Container>
            <Grid
              h="30vh"
              templateRows="repeat(2, 1fr)"
              templateColumns="repeat(12, 1fr)"
              gap={4}
            >
              {makeWorks(upper)}
            </Grid>
          </Container>
        </Box.Absolute>
        <Box.Absolute
          bottom="0"
          left="-6%"
          right="-6%"
        >
          <Container>
            <Grid
              h="35vh"
              templateRows="repeat(2, 1fr)"
              templateColumns="repeat(14, 1fr)"
              gap={4}
            >
              {makeWorks(lower)}
            </Grid>
          </Container>
        </Box.Absolute>
      </Media>
      <Media greaterThanOrEqual="md">
        <Grid
          pos="absolute"
          top="0"
          left="-1em"
          bottom="0"
          width="50%"
          templateRows="repeat(16, 1fr)"
          templateColumns="repeat(10, 1fr)"
          gap={4}
        >
          {makeWorks(desktopLeft, true)}
        </Grid>
        <Grid
          pos="absolute"
          top="0"
          right="-1em"
          bottom="0"
          width="50%"
          templateRows="repeat(16, 1fr)"
          templateColumns="repeat(10, 1fr)"
          gap={4}
        >
          {makeWorks(desktopRight, true)}
        </Grid>
      </Media>
    </>
  )
}

export default Value
