import { Container, HStack } from '@chakra-ui/layout'
import { AnimatePresence, motion } from 'framer-motion'
import { range } from 'lodash'
import React from 'react'

import BackgroundImage from '../../../components/BackgroundImage'
import Box from '../../../components/Box'
import { responsive } from '../../../contexts/responsive'
import envelope from './envolope.svg'

const Cooperate = ({ in: isIn }) => {
  return (
    <Box.Absolute left="0" right="0" top="35vh" transform="translateY(-100%)">
      <Container>
      <HStack maxW={responsive("16em", '30em')} mx="auto">
          <AnimatePresence>
            {range(3).map(i => isIn && (
              <Box.Relative
                as={motion.div}
                key={i}
                flex="1"
                initial={{ y: '-100%', opacity: 0 }}
                animate={{ y: 0, opacity: 1, transition: { duration: 0.75, delay: i * 0.2 } }}
                exit={{ y: 0, opacity: 0 }}
              >
                <BackgroundImage src={envelope} ratio={158.5 / 98.5} />
                {i === 1 && (
                  <Box.Absolute
                    as={motion.div}
                    top="-0.5em"
                    left="0.25em"
                    width="100%"
                    initial={{ y: '-100%', opacity: 0 }}
                    animate={{ y: 0, opacity: 1, transition: { duration: 0.75, delay: 1 + i * 0.2 } }}
                  >
                    <BackgroundImage src={envelope} ratio={158.5 / 98.5} />
                  </Box.Absolute>
                )}
              </Box.Relative>
            ))}
          </AnimatePresence>
        </HStack>
      </Container>
    </Box.Absolute>
  )
}

export default Cooperate
