import { createContext } from "react";

const fullpageContext = createContext({
  pageTimeout: 500,
  currentPage: 0,
  currentDirection: '',
  currentAnchor: '',
  setCurrentPage: () => {},
  setCurrentDirection: () => {},
  getFullpageApi: () => {},
})

export default fullpageContext
