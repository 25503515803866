import React from 'react'
import { Heading, HStack } from '@chakra-ui/layout';

import useTextTransition from '../../hooks/useTextTransition'
import ReColon from '../../components/Logos/ReColon'
import SVG from '../../components/SVG';
import Box from '../../components/Box'
import { responsive } from '../../contexts/responsive';

const AnimateTitle = ({ children = '', delay, active, ...props }) => {
  const aniTitle = useTextTransition(children, { delay, duration: 500 })
  // console.log(aniTitle, aniTitle.length)

  return (
    <HStack justifyContent="center" fontSize={responsive('1.125em', '2em')} {...props}>
      <ReColon color="black" width="3.5em" />
      <Box.Relative overflow="hidden" textAlign="left">
        <Heading fontSize="2.175em" letterSpacing="0" display="block" height="1.35em" lineHeight="1" color="transparent">
          {aniTitle}
        </Heading>
        <Box.Absolute left="0" top="0" py="0.375em" pointerEvents="none">
          <SVG viewBox="0 0 460 88" width="12em">
            <text
              x="0"
              y="68"
              fontSize="86"
              fill="none"
              stroke="#000"
              strokeWidth="2"
              fontFamily="Barlow-Medium, Barlow"
              fontWeight="500"
              letterSpacing="-0.3"
            >
              {aniTitle}
            </text>
          </SVG>
        </Box.Absolute>
      </Box.Relative>
    </HStack>
  )
}

export default AnimateTitle
