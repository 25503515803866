import { StaticImage } from 'gatsby-plugin-image'
import React, { useCallback, useEffect, useState } from 'react'
import { motion } from 'framer-motion'

import Text from '../../components/Text'
import Box from '../../components/Box'
import SVG from '../../components/SVG'
import { responsive } from '../../contexts/responsive'

import useTextTransition from '../../hooks/useTextTransition'

const repeat = Infinity
const blinkDuration = 0.7
const blinkA = [0, 1, 0]
const blinkB = [1, 0, 1]

// const labAnimation = {
//   variants: {
//     base: { opacity: 0 },
//     active: { opacity: 1 },
//   },
//   transition: { stiffness: 1000 }
// }

const AnimateLogo = ({ onComplete, ...props }) => {
  return (
    <SVG viewBox="0 0 568 136" {...props}>
      <g fill="currentColor">
        <g>
          <path d="M71.41,124.09,49,76.28a.63.63,0,0,0-.64-.48h-23a.57.57,0,0,0-.64.64v47.17a1.41,1.41,0,0,1-1.59,1.59H11a1.41,1.41,0,0,1-1.59-1.59V14.88A1.41,1.41,0,0,1,11,13.28H54a34.52,34.52,0,0,1,16.87,4A28.16,28.16,0,0,1,82.12,28.47a33.63,33.63,0,0,1,4,16.63q0,11.19-5.84,18.95A28.63,28.63,0,0,1,64.06,74.52c-.43.22-.54.48-.32.8l23.18,48a1.87,1.87,0,0,1,.16.8c0,.74-.43,1.11-1.28,1.11H73.17A1.75,1.75,0,0,1,71.41,124.09ZM24.72,27.2V63a.57.57,0,0,0,.64.64h26.7q8.47,0,13.6-5t5.11-13.35q0-8.48-5.11-13.59t-13.6-5.11H25.36A.57.57,0,0,0,24.72,27.2Z"/>
          <path d="M180.13,26.56H123.37a.56.56,0,0,0-.64.64V61.57a.56.56,0,0,0,.64.64h48.08a1.42,1.42,0,0,1,1.6,1.6V73.88a1.42,1.42,0,0,1-1.6,1.6H123.37a.57.57,0,0,0-.64.64v35.17a.56.56,0,0,0,.64.64h56.76a1.42,1.42,0,0,1,1.6,1.6v10.08a1.41,1.41,0,0,1-1.6,1.59H109a1.41,1.41,0,0,1-1.6-1.59V14.88a1.42,1.42,0,0,1,1.6-1.6h71.15a1.42,1.42,0,0,1,1.6,1.6V25A1.42,1.42,0,0,1,180.13,26.56Z"/>
        </g>
        <g>
          <path d="M284.91,123.61V14.88a1.41,1.41,0,0,1,1.59-1.6h12.16a1.41,1.41,0,0,1,1.59,1.6v96.41a.57.57,0,0,0,.64.64h55.16a1.42,1.42,0,0,1,1.6,1.6v10.08a1.41,1.41,0,0,1-1.6,1.59H286.5A1.41,1.41,0,0,1,284.91,123.61Z"/>
          <path d="M447.35,123.93l-8.87-28.74a.63.63,0,0,0-.64-.48H399.22a.62.62,0,0,0-.64.48l-8.95,28.74a1.86,1.86,0,0,1-1.92,1.27H374.76c-1.18,0-1.6-.58-1.28-1.75l35-108.89a1.87,1.87,0,0,1,1.92-1.28h16a1.87,1.87,0,0,1,1.92,1.28l35.33,108.89.16.64c0,.74-.48,1.11-1.43,1.11H449.26A1.86,1.86,0,0,1,447.35,123.93Zm-44.7-41.61a.77.77,0,0,0,.56.24h30.47a.76.76,0,0,0,.56-.24.44.44,0,0,0,.08-.56L418.89,32.47a.52.52,0,0,0-.48-.32.5.5,0,0,0-.48.32L402.57,81.76A.45.45,0,0,0,402.65,82.32Z"/>
          <path d="M541.68,67.81q16.94,7.18,16.94,26.7,0,14.7-9.75,22.7t-25.42,8H483.64a1.41,1.41,0,0,1-1.6-1.59V14.88a1.42,1.42,0,0,1,1.6-1.6h38.53q16.64,0,25.82,7.6T557.19,43q0,17.28-15.51,23.83Q540.87,67.33,541.68,67.81ZM497.39,27.2V60.93a.56.56,0,0,0,.64.64h24.14q9.27,0,14.63-4.64t5.36-12.63q0-8.14-5.36-12.95t-14.63-4.79H498A.56.56,0,0,0,497.39,27.2Zm40.53,79.54q5.36-5,5.36-13.67T537.84,79.4q-5.45-5-14.87-5H498a.57.57,0,0,0-.64.64v36.13a.56.56,0,0,0,.64.64h25.42Q532.57,111.77,537.92,106.74Z"/>
        </g>
        <g>
          <g>
            <motion.path
              animate={{ opacity: blinkB }}
              transition={{ duration: blinkDuration, repeat }}
              d="M233.82,16.39A22.21,22.21,0,1,0,256,38.6,22.21,22.21,0,0,0,233.82,16.39Zm0,35.2a13,13,0,1,1,13-13A13,13,0,0,1,233.82,51.59Z"
            />
            <motion.circle
              animate={{ opacity: blinkA }}
              transition={{ duration: blinkDuration, repeat }}
              cx="233.82"
              cy="38.6"
              r="22.2"
            />
          </g>
          <g>
            <motion.path
              animate={{ opacity: blinkA }}
              transition={{ duration: blinkDuration, repeat }}
              d="M233.82,81A22.21,22.21,0,1,0,256,103.2,22.2,22.2,0,0,0,233.82,81Zm0,35.19a13,13,0,1,1,13-13A13,13,0,0,1,233.82,116.19Z"
            />
            <motion.circle
              animate={{ opacity: blinkB }}
              transition={{ duration: blinkDuration, repeat }}
              cx="233.82"
              cy="103.2"
              r="22.2"
            />
          </g>
        </g>
      </g>
    </SVG>
  )
}

const slogans = [
  'From Content to Context',
  '持續實驗，不斷創造有共鳴的溝通體驗'
]

const MotionText = motion(Text)

const Slogan = ({
  in: isActive,
}) => {
  const [sloIndex, setSloIndex] = useState(0)
  const [leaved, setLeaved] = useState()
  const onEnterEnd = useCallback(() => {
    if (isActive) {
      setTimeout(() => setSloIndex(1), 1000)
    }
  }, [isActive])
  const onLeaveEnd = useCallback(() => {
    if (sloIndex) {
      setLeaved(true)
    }
  }, [sloIndex])
  const aniTitle = useTextTransition(isActive ? slogans[sloIndex] : '　', {
    delay: 0,
    duration: 500,
    onEnterEnd,
    onLeaveEnd,
  })

  return (
    <Box
      fontSize={leaved ? responsive('3.5vw', '3vmin') : responsive('4.5vw', '3.75vmin')}
      h="2.5rem"
    >
      <Text
        as="h3"
        letterSpacing="0.15em"
        fontWeight="500"
      >
        {aniTitle}
      </Text>
    </Box>
  )
}

const Cover = ({ active, loaded }) => {
  const isActive = loaded && active
  return (
    <Box.Relative height="100%">
      <Box.FullAbs bg="black">
        <StaticImage
          src="home-bg.jpg"
          layout="fullWidth"
          style={{
            width: '100%',
            height: '100%',
            opacity: 0.7,
          }}
          alt="RE:LAB辦公室照片"
        />
      </Box.FullAbs>
      <Box.Relative bg="rgba(0,0,0,0.3)" color="white" textAlign="center" height="100%" top="0">
        <Box.AbsCenter>
          <AnimateLogo width={responsive('72%', '60vmin')} mb="1em" />
          <Slogan in={isActive} />
        </Box.AbsCenter>
        <motion.div
          style={{
            left: '50%',
            bottom: '5%',
            x: '-50%',
            opacity: 0,
            position: 'absolute',
          }}
          animate={active ? { opacity: 1 } : {}}
          transition={{ duration: 1, delay: 3 }}
        >
          <motion.div
            animate={active ? {
              y: ['0%', '15%', '0%'],
            } : {}}
            transition={{
              delay: 5.5,
              duration: 1,
              ease: "backOut",
              repeat: Infinity,
              repeatDelay: 2
            }}
          >
            <MotionText
              fontSize={responsive('0.875em', '1.125em')}
              lineHeight="2"
              style={{
                opacity: 0,
              }}
              animate={active ? { opacity: 1 } : {}}
              transition={{ duration: 1, delay: 3.8 }}
            >開啟對話</MotionText>
            <SVG viewBox="0 0 66 186" color="white" width={responsive('1.125em', '1.25em')} transform="translateY(-27.5%)">
              <g fill="currentColor" transform="translate(0, 60)">
                <path d="M33,81a22.21,22.21,0,1,0,22.2,22.2A22.2,22.2,0,0,0,33,81Zm0,35.19a13,13,0,1,1,13-13A13,13,0,0,1,33,116.19Z" />
                <motion.circle
                  cx="33.02"
                  cy="38.6"
                  r="22.2"
                  style={{
                    y: -80,
                  }}
                  animate={active ? { y: 0 } : {}}
                  transition={{ duration: 0.8, delay: 3 }}
                />
              </g>
            </SVG>
          </motion.div>
        </motion.div>
      </Box.Relative>
    </Box.Relative>
  )
}

export default Cover
