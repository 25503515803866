import React, { createElement, useContext, useEffect, useRef, useState } from 'react'
import { Circle, HStack, Container } from '@chakra-ui/layout'
import { AnimatePresence, motion } from 'framer-motion'
import Parallax from 'parallax-js'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { Link } from 'gatsby'

import BackgroundImage from '../../../components/BackgroundImage'
import Box from '../../../components/Box'
import Text from '../../../components/Text'
import { responsive } from '../../../contexts/responsive'
import Popover from '../../../components/Popover'
import Colon from '../../../components/Logos/Colon'

import base from './base.svg'
import AnimateTitle from '../AnimateTitle'
import fullpageContext from '../fullpageContext'
import useDelayedOpen from '../Adventure/useDelayedOpen'

const shining = keyframes`
  0%, 50% {
    opacity: 1;
    transition: 0.5s;
  }
  25%, 75% {
    opacity: 0;
    transition: 0.5s;
  }
`

const Anime = styled(Box)`
  animation: ease-in-out ${shining} 2.5s;
`

const StyledPopover = styled(Popover)`
  .chakra-popover__popper {
    inset: 0px auto auto 0px;
  }
`

const texts = [
  '嗨，我是島懶員1號',
  '讓我帶你們看RE：LAB自己發起的計畫。一路順著地心引力往下，輕鬆不出汗！',
]

const Start = ({ direction, active, textIndex = 0 }) => {
  const { isOpen, onOpen, onClose } = useDelayedOpen(active, textIndex || direction)
  const prevActive = useRef()
  const { currentPage } = useContext(fullpageContext)
  const [show, setShow] = useState(textIndex === 1)
  const [goDown, setGoDown] = useState()
  const circleRef = useRef()
  useEffect(() => {
    if(active) {
      setTimeout(() => setShow(true), 1200)
    }
  }, [active])
  useEffect(() => {
    if (textIndex === 1) {
      if (active) {
        if (direction === 'up') {
          setGoDown(false)
        }
        onOpen()
      } else if (prevActive.current && direction === 'down') {
        setGoDown(true)
        onClose()
      }
      prevActive.current = active
    }
  }, [active, direction, textIndex])
  useEffect(() => {
    new Parallax(circleRef.current)
  }, [])
  return (
    <Box>
      <Box.Absolute left="0" right="0" top="50%" transform="translateY(-50%)">
        <AnimatePresence>
          <Box.Absolute
            as={motion.div}
            textAlign="center"
            top="0"
            left="0"
            right="0"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.5 } }}
            exit={{ opacity: 0 }}
            transform="translateY(-110%)"
          >
            <AnimateTitle fontSize={responsive('1.625em', '2em')} delay={direction === 'up' ? 200 : 700}>
              {currentPage >= 4 ? 'start' : ''}
            </AnimateTitle>
          </Box.Absolute>
        </AnimatePresence>
        <Container>
          <Box maxWidth={responsive('14.125em', '18em')} mx="auto">
            <BackgroundImage.Lazy src={base} ratio={468 / 484} />
          </Box>
          {/* <Box.Absolute left="0" right="0" top="50%" transform="translateY(-50%)">
            <div>
              <Anime display={show ? 'block' : 'none'}>
                <Circle ref={circleRef} mx="auto" bg="black" size="3em" mb="2em">
                  <div data-depth="-1.5">
                    <HStack spacing="0.25em">
                      <Circle bg="white" size="4px" />
                      <Circle bg="white" size="4px" />
                    </HStack>
                  </div>
                </Circle>
              </Anime>
            </div>
          </Box.Absolute> */}
        </Container>
      </Box.Absolute>
      <StyledPopover
        onOpen={onOpen}
        onClose={onClose}
        isOpen={isOpen}
        contentTitle={texts[textIndex]}
      >
        <motion.div
          style={{ y: '0' }}
          animate={{
            y: goDown ? '60vh' : '0',
          }}
          transition={{ duration: 0.7 }}
        >
          <Circle opacity={show ? 1 : 0} ref={circleRef} mx="auto" bg="black" size={responsive('11vw', '4.5vw')} mb="2em">
            <div data-depth="-1.5">
              <HStack spacing="0.25em">
                <Circle bg="white" size="4px" />
                <Circle bg="white" size="4px" />
              </HStack>
            </div>
          </Circle>
        </motion.div>
      </StyledPopover>
      <Box
        left="50%"
        bottom="5%"
        transform={'translateX(-50%)'}
        position="absolute"
        textAlign={'center'}
      >
        <Text fontSize={responsive('0.875em', '1em')} lineHeight="2">
          {createElement(textIndex === 1 ? Link : 'span', {
            to: '/base',
          }, '往下滑開啟導覽')}
        </Text>
        <Colon width="1.125em" />
      </Box>
    </Box>
  )
}

export default Start
