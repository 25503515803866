import React from 'react'
import { motion } from 'framer-motion'

import SVG from './SVG'

const repeat = Infinity
const blinkDuration = 0.7

const AnimateReColon = (props) => {
  return (
    <SVG {...props} viewBox="0 0 100 50" fill="currentColor">
      <path d="M26.47,46,17.88,27.66a.24.24,0,0,0-.24-.18H8.8c-.17,0-.25.08-.25.24V45.83a.54.54,0,0,1-.61.61H3.27a.54.54,0,0,1-.61-.61V4.09a.54.54,0,0,1,.61-.61H19.78A13.26,13.26,0,0,1,26.26,5a10.92,10.92,0,0,1,4.33,4.3,13,13,0,0,1,1.53,6.38A11.79,11.79,0,0,1,29.88,23a11,11,0,0,1-6.23,4c-.16.08-.21.18-.12.3l8.9,18.42a.67.67,0,0,1,.06.3c0,.29-.17.43-.49.43H27.15A.68.68,0,0,1,26.47,46ZM8.55,8.82V22.57c0,.16.08.24.25.24H19.05a7.09,7.09,0,0,0,5.21-1.93,6.87,6.87,0,0,0,2-5.12,7.06,7.06,0,0,0-2-5.22,7,7,0,0,0-5.21-2H8.8A.22.22,0,0,0,8.55,8.82Z" />
      <path d="M68.21,8.57H46.42a.23.23,0,0,0-.25.25V22a.22.22,0,0,0,.25.24H64.88a.54.54,0,0,1,.61.61v3.87a.55.55,0,0,1-.61.62H46.42a.22.22,0,0,0-.25.24V41.1a.22.22,0,0,0,.25.25H68.21a.54.54,0,0,1,.61.61v3.87a.54.54,0,0,1-.61.61H40.9a.54.54,0,0,1-.62-.61V4.09a.55.55,0,0,1,.62-.61H68.21a.54.54,0,0,1,.61.61V8A.54.54,0,0,1,68.21,8.57Z" />
      <motion.g
        animate={{ opacity: [0, 1, 0] }}
        transition={{ duration: blinkDuration, repeat }}
      >
        <path d="M88.82,29.47A8.53,8.53,0,1,0,97.34,38,8.53,8.53,0,0,0,88.82,29.47Zm0,13.51a5,5,0,1,1,5-5A5,5,0,0,1,88.82,43Z" />
        <circle cx="88.82" cy="13.2" r="7.81" transform="translate(-0.97 14.4) rotate(-9.22)" />
      </motion.g>
      <motion.g
        animate={{ opacity: [1, 0, 1] }}
        transition={{ duration: blinkDuration, repeat }}
      >
        <path d="M88.82,22.43a8.52,8.52,0,1,0-8.53-8.52A8.52,8.52,0,0,0,88.82,22.43Zm0-13.51a5,5,0,1,1-5,5A5,5,0,0,1,88.82,8.92Z" />
        <circle cx="88.82" cy="38.71" r="7.81" transform="translate(-5.05 14.73) rotate(-9.22)" />
      </motion.g>
    </SVG>
  )
}

export default AnimateReColon
