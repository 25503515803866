import { Popover as ChPopover, PopoverBody, PopoverContent, PopoverFooter, PopoverTrigger } from '@chakra-ui/popover'
import { Button, Box } from '@chakra-ui/react'
import React from 'react'
import { responsive } from '../../contexts/responsive'

import Text from '../Text'
import BackgroundImage from '../BackgroundImage'
import arrow from './tooltip-arrow.svg'

const Popover = ({
  isOpen,
  children,
  contentTitle,
  content,
  action,
  actionLabel = '點擊進入',
  onClose,
  onOpen,
  placement,
  maxWidth = '16em',
  arrowDirection = 'left',
}) => {
  return (
    <ChPopover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement={placement}
      minWidth={`50%`}
      gutter={32}
      closeOnBlur={false}
    >
      <PopoverTrigger>
        {children}
      </PopoverTrigger>
      <PopoverContent borderRadius={'1.5em'} bg="black" color="white" borderColor="black" p="2" maxWidth={maxWidth}>
        <Box position="absolute" width="1em" top="0" left="50%" transform={`translate(-50%, -100%) scale(${arrowDirection === 'right' ? '-' : ''}1, 1)`}>
          <BackgroundImage src={arrow} ratio={35 / 42} />
        </Box>
        <PopoverBody>
          <Text letterSpacing={'0.125rem'} fontSize={responsive('1em', '1.25em')}>{contentTitle}</Text>
          <Text textAlign={'justify'} fontSize={responsive('0.8125em', '1em')}>{content}</Text>
        </PopoverBody>
        {action && (
          <PopoverFooter>
            <Button to={action}>{actionLabel}</Button>
          </PopoverFooter>
        )}
      </PopoverContent>
    </ChPopover>
  )
}

export default Popover
